var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "p-3" }, [
        _c("div", {}, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-password-input" } }, [
                  _vm._v("N° Contrat")
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ODS_info.ID_CTT.ref,
                      expression: "ODS_info.ID_CTT.ref"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { readonly: "" },
                  domProps: { value: _vm.ODS_info.ID_CTT.ref },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.ODS_info.ID_CTT, "ref", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-inputCity" } }, [
                  _vm._v("Nom de la Base *")
                ]),
                _vm.ODS_info.lifebase
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ODS_info.lifebase.name,
                          expression: "ODS_info.lifebase.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { readonly: "" },
                      domProps: { value: _vm.ODS_info.lifebase.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.ODS_info.lifebase,
                            "name",
                            $event.target.value
                          )
                        }
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ODS_info.Bdv,
                          expression: "ODS_info.Bdv"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { readonly: "" },
                      domProps: { value: _vm.ODS_info.Bdv },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.ODS_info, "Bdv", $event.target.value)
                        }
                      }
                    })
              ])
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Prestataire *")]),
              _vm.ODS_info.prestataire
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ODS_info.prestataire.prestname,
                        expression: "ODS_info.prestataire.prestname"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { readonly: "" },
                    domProps: { value: _vm.ODS_info.prestataire.prestname },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ODS_info.prestataire,
                          "prestname",
                          $event.target.value
                        )
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ODS_info.prestataire,
                        expression: "ODS_info.prestataire"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { readonly: "" },
                    domProps: { value: _vm.ODS_info.prestataire },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.ODS_info,
                          "prestataire",
                          $event.target.value
                        )
                      }
                    }
                  })
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Date ODS")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ODS_info.date_ODS,
                    expression: "ODS_info.date_ODS"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "date", readonly: "" },
                domProps: { value: _vm.ODS_info.date_ODS },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ODS_info, "date_ODS", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Date execution")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ODS_info.date_exec_ODS,
                    expression: "ODS_info.date_exec_ODS"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "date", readonly: "" },
                domProps: { value: _vm.ODS_info.date_exec_ODS },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ODS_info, "date_exec_ODS", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "col-lg-4" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Type de Periode *")
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ODS_info.period,
                      expression: "ODS_info.period"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { disabled: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.ODS_info,
                        "period",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "0" } }, [
                    _vm._v("Selectionner la periode")
                  ]),
                  _vm._l(_vm.periods, function(period) {
                    return _c(
                      "option",
                      { key: period.id, domProps: { value: period.id } },
                      [_vm._v(_vm._s(period.designation))]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "formrow-inputCity" } }, [
                  _vm._v("Commentaires")
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ODS_info.comments,
                      expression: "ODS_info.comments"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { cols: "30", rows: "1", readonly: "" },
                  domProps: { value: _vm.ODS_info.comments },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.ODS_info, "comments", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "p-3" },
        [
          _c("div", { staticClass: "row d-flex justify-content-between" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col text-right" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: { disabled: true, name: "check-button", switch: "" },
                    model: {
                      value: _vm.ODS_info.is_extra,
                      callback: function($$v) {
                        _vm.$set(_vm.ODS_info, "is_extra", $$v)
                      },
                      expression: "ODS_info.is_extra"
                    }
                  },
                  [_vm._v(" Supplémentaire ")]
                )
              ],
              1
            )
          ]),
          _vm._l(_vm.RepasService, function(rep, i) {
            return _c("div", { key: i, staticClass: "row mt-2" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("repas", {
                    key: rep,
                    attrs: {
                      is_extra: _vm.ODS_info.is_extra,
                      ODS: _vm.ODS_info.items,
                      pos: rep,
                      service: _vm.filtredRepas,
                      send: _vm.send
                    },
                    on: {
                      getvalues: function($event) {
                        _vm.Repas["" + rep] = $event
                      }
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _vm.ODS_info.id
          ? _c(
              "div",
              { staticClass: "col text-right mr-2 mb-3" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-label mr-2",
                    attrs: {
                      disabled: _vm.ODS_info.verified == 1,
                      variant: "info"
                    },
                    on: {
                      click: function($event) {
                        return _vm.getTotalAmountODS()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "bx bx-check label-icon" }),
                    _vm._v(" Contrôler ")
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-label mr-2",
                    attrs: {
                      disabled: _vm.ODS_info.verified == 1 || _vm.blocked,
                      variant: "primary"
                    },
                    on: { click: _vm.onComplete }
                  },
                  [
                    _c("i", { staticClass: "bx bx-check-double label-icon" }),
                    _vm._v(" Enregitrer & Valider ")
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h4", [_vm._v("Type de Repas")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }