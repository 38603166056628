var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
      [
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { "no-body": "" } },
          [
            _c(
              "b-card-header",
              {
                staticClass: "p-1",
                attrs: { "header-tag": "header", role: "tab" }
              },
              [
                _c(
                  "b-button",
                  {
                    staticStyle: {
                      background: "#e5e9f0",
                      color: "black !important",
                      border: "none !important"
                    },
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        _vm.active = !_vm.active
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3 text-left mt-1" }, [
                        _c("i", {
                          staticClass: "mdi mdi-format-list-bulleted"
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.service[this.pos][0].meal_service.designation
                            )
                        )
                      ]),
                      _c("div", { staticClass: "col text-right" }, [
                        _c("span", [
                          _vm._v(
                            "Total Repas Demander: " +
                              _vm._s(_vm.getTotal()) +
                              " "
                          )
                        ]),
                        _vm._v(" / "),
                        _c("span", [
                          _vm._v(
                            "Total Réaliser: " +
                              _vm._s(_vm.getTotalR()) +
                              " / Total Vérifier : " +
                              _vm._s(_vm.getTotalV()) +
                              " / Prix Vérification : " +
                              _vm._s(
                                new Intl.NumberFormat("de-DE", {
                                  style: "currency",
                                  currency: "DZD"
                                }).format(_vm.getTotalVCash())
                              ) +
                              " "
                          )
                        ]),
                        _vm.active
                          ? _c("i", {
                              staticClass: "mdi mdi-chevron-up font-size-18"
                            })
                          : _c("i", {
                              staticClass: "mdi mdi-chevron-down font-size-18"
                            })
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: "accordion-" + _vm.pos,
                  visible: _vm.active,
                  accordion: "my-accordion",
                  role: "tabpanel"
                }
              },
              [
                _c(
                  "b-card-body",
                  { staticClass: "shadow-lg" },
                  _vm._l(_vm.keys, function(repas, i) {
                    return _c("div", { key: i, staticClass: "mt-3" }, [
                      _c("h5", [_vm._v(_vm._s(repas))]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Consommation Demander")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data[repas]["meal_amount"],
                                expression: "data[repas]['meal_amount']"
                              }
                            ],
                            staticClass: "form-control bg-light",
                            attrs: { type: "text", readonly: "" },
                            domProps: { value: _vm.data[repas]["meal_amount"] },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.data[repas],
                                  "meal_amount",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Consommation Réaliser")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data[repas]["meal_achieve_amount"],
                                expression: "data[repas]['meal_achieve_amount']"
                              }
                            ],
                            staticClass: "form-control bg-light",
                            attrs: {
                              type: "number",
                              min: "0",
                              readonly: "",
                              placeholder: "50"
                            },
                            domProps: {
                              value: _vm.data[repas]["meal_achieve_amount"]
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.data[repas],
                                  "meal_achieve_amount",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Consommation minimum")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data[repas].prestation.default_qty,
                                expression: "data[repas].prestation.default_qty"
                              }
                            ],
                            staticClass: "form-control bg-light",
                            attrs: {
                              type: "number",
                              min: "0",
                              readonly: "",
                              placeholder: "50"
                            },
                            domProps: {
                              value: _vm.data[repas].prestation.default_qty
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.data[repas].prestation,
                                  "default_qty",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Consommation Vérfié")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.data[repas]["meal_verified_amount"],
                                expression:
                                  "data[repas]['meal_verified_amount']"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              min: "0",
                              placeholder: "50"
                            },
                            domProps: {
                              value: _vm.data[repas]["meal_verified_amount"]
                            },
                            on: {
                              keypress: function($event) {
                                return _vm.isNumber($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.data[repas],
                                  "meal_verified_amount",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      parseInt(_vm.data[repas]["meal_verified_amount"]) != 0 &&
                      parseInt(_vm.data[repas]["meal_verified_amount"]) !=
                        parseInt(_vm.data[repas]["meal_amount"])
                        ? _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "formrow-inputCity" } },
                                  [_vm._v("Motif de différence")]
                                ),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data[repas]["motif"],
                                      expression: "data[repas]['motif']"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { cols: "30", rows: "1" },
                                  domProps: { value: _vm.data[repas]["motif"] },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.data[repas],
                                        "motif",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }