<script>
import appConfig from "@/app.config";
export default {
    props:['service','ODS','send','pos','is_extra'],
    data() {
        return {
            active:false,
            keys:[],
            data:{},
        }
    },
    watch:{
        pos(){
            // console.log(this.pos);
        },
        send:{
            handler(val){
                if(val) this.resetData();
            }
        },
        
    },
    mounted(){
        this.resetData();
        for (let index = 0; index < this.service[this.pos].length; index++) {
                this.data[this.service[this.pos][index].meal_type.designation] = this.service[this.pos][index]
                this.data[this.service[this.pos][index].meal_type.designation].motif = ""
                if(this.service[this.pos][index].meal_verified_amount == "") this.data[this.service[this.pos][index].meal_type.designation].meal_verified_amount = 0;

                if(this.service[this.pos][index].total_amount) this.data[this.service[this.pos][index].meal_type.designation].unit_price  = this.service[this.pos][index].prestation.unit_price;
                else this.data[this.service[this.pos][index].meal_type.designation].unit_price =this.service[this.pos][index].unit_price;
            
        }
        this.keys = Object.keys(this.data);
    },
    methods:{
        emitData(){
            this.$emit('getvalues',this.data);
        },
        resetData(){
            this.data = {}
        },
        getTotal(){
            if(this.service[this.pos][0].meal_amount){
                let total = 0;
                this.service[this.pos].forEach(element => {
                    total+=parseInt(element.meal_amount);
                });
                // if (total > 0) this.emitData();
                return total;
            }
            let totalC = 0;
            this.keys.forEach(element => {
                totalC += element.meal_amount
            })
            // totalC = parseInt(this.data.Ordinary_today.meal_amount) + parseInt(this.data.Improved_today.meal_amount) + parseInt(this.data.VIP_today.meal_amount);
            // if (totalR>0) this.emitData();
            return totalC;
        },
        getTotalR(){
            if(this.service[this.pos][0].meal_achieve_amount && this.service[this.pos][0].meal_achieve_amount != ""){
                let totalR = 0;
                this.service[this.pos].forEach(element => {
                    totalR+=parseInt(element.meal_achieve_amount);
                });
                if (totalR > 0) this.emitData();
                return totalR;
            }
            let totalR = 0
            this.keys.forEach(element => {
                totalR += element.meal_achieve_amount
            })
            // let totalR = parseInt(this.data.Ordinary_today['meal_achieve_amount']) + parseInt(this.data.Improved_today['meal_achieve_amount']) + parseInt(this.data.VIP_today['meal_achieve_amount']);
            // if (totalR>0) this.emitData();
            return totalR;
        },
        getTotalV(){
            if(this.service[this.pos][0].meal_verified_amount && this.service[this.pos][0].meal_verified_amount != ""){
                let totalR = 0;
                this.service[this.pos].forEach(element => {
                    totalR+=parseInt(element.meal_verified_amount);
                });
                if (totalR > 0) {this.emitData(); this.getTotalVCash}
                return totalR;
            }
            let totalR = 0
            this.keys.forEach(element => {
                totalR += element.meal_verified_amount
            })
            // let totalR = parseInt(this.data.Ordinary_today['meal_verified_amount']) + parseInt(this.data.Improved_today['meal_verified_amount']) + parseInt(this.data.VIP_today['meal_verified_amount']);
            if (totalR>0) {this.emitData(); this.getTotalVCash} else totalR=0;
            return totalR;
        },
        getTotalVCash(){
            if(this.service[this.pos][0].meal_verified_amount && this.service[this.pos][0].meal_verified_amount != ""){
                let totalR = 0;
                this.service[this.pos].forEach(element => {
                    if(parseInt(element.meal_verified_amount) < parseInt(element.prestation.default_qty) && this.is_extra != 1) totalR+=(parseInt(element.prestation.default_qty) * parseInt(element.unit_price));
                    else totalR+=(parseInt(element.meal_verified_amount) * parseInt(element.unit_price));
                });
                if (totalR > 0) this.emitData();
                return totalR;
            }

            let totalR = 0;
            
            this.keys.forEach(element => {
                let Qte=0;
                if(this.is_extra != 1) Qte = (parseInt(this.data[element].meal_verified_amount) < parseInt(this.data[element].prestation.default_qty)) ? parseInt(this.data[element].prestation.default_qty) : parseInt(this.data[element].meal_verified_amount)
                else Qte = parseInt(this.data[element].meal_verified_amount);
                totalR += (Qte * this.data[element].unit_price)

            })
            if (totalR>0) this.emitData(); else totalR=0;
            return totalR;
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                this.getTotalV();
            }
        }
    }
}
</script>
<template>
    <div>
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col-md-3 text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> {{service[this.pos][0].meal_service.designation}}</div>
                  <div class="col text-right">
                      <span>Total Repas Demander: {{getTotal()}} </span> / <span>Total Réaliser: {{getTotalR()}} / Total Vérifier : {{getTotalV()}} / Prix Vérification : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(getTotalVCash() ) }} </span>
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${pos}`" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
                    <div class="mt-3" v-for="(repas,i) in keys" :key="i">
                        <h5>{{repas}}</h5>
                        <div class="row">
                            <div class="col-lg-3">
                                <label for="">Consommation Demander</label>
                                <input type="text" class="form-control bg-light" readonly v-model="data[repas]['meal_amount']" >
                            </div>
                            <div class="col-lg-3">
                                <label for="">Consommation Réaliser</label>
                                <input type="number" min="0" class="form-control bg-light" readonly v-model="data[repas]['meal_achieve_amount']"  placeholder="50">
                            </div>
                            <div class="col-lg-3">
                                <label for="">Consommation minimum</label>
                                <input type="number" min="0" class="form-control bg-light" readonly v-model="data[repas].prestation.default_qty"  placeholder="50">
                            </div>
                            <div class="col-lg-3">
                                <label for="">Consommation Vérfié</label>
                                <input type="number" min="0" class="form-control" v-model="data[repas]['meal_verified_amount']" @keypress="isNumber($event)"  placeholder="50">
                            </div>
                        </div>
                        <div class="row mt-2" v-if="parseInt(data[repas]['meal_verified_amount'])!= 0 && (parseInt(data[repas]['meal_verified_amount']) != parseInt(data[repas]['meal_amount']))">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Motif de différence</label>
                                <textarea
                                  v-model="data[repas]['motif']"
                                  class="form-control"
                                  cols="30"
                                  rows="1"
                                ></textarea>
                              </div>
                            </div>
                        </div>
                        
                    </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
    </div>
</template>